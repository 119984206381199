<template>
    <div class="message-container">
        <div class="head-con">
            <h3>我的消息</h3>
        </div>
        <div class="message-content" v-if="list.length>0">

        </div>
        <div class="onmessage-content">
            <img src="@/assets/image/nomeaasge.png" />
            <p>你还没有任何消息哦~</p>
        </div>
    </div>
</template>
<script>
import {onMounted,reactive,ref, toRefs} from 'vue'
import {getData} from '@/utils/req'
export default {
    setup(){
        let pc_user = ref('')
        let page = ref(0)
        let state = reactive({
            list:[],
            total:''
        })
        onMounted(()=>{
            pc_user.value = JSON.parse(localStorage.getItem('pc_user'))
            axios()
        })
        const axios =()=>{
            const data= {
                module: 'app_pc',
                action: 'message',
                m: 'index',
                access_id:pc_user.value.access_id,
                page:page.value
            }
            getData(data).then(res=>{
                if(res.code === 200){
                    state.list = res.data.message
                    state.total = res.data.total
                }
            })
        }
        return {
            ...toRefs(state)
        }
    }
}
</script>
<style scoped>
    .message-container{
        background: #fff;
        width: 100%;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .message-container .head-con{
        width: 100%;
        height: 56px;
        border-bottom: 1px solid #E6E6E6;
    }
    .message-container .head-con h3{
        color: #020202;
        font-size: 16px;
        font-weight: bold;
        padding-left: 16px;
        line-height: 56px;
    }
    .onmessage-content{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        flex: 1;
    }
    .onmessage-content p{
        font-size: 16px;
        line-height: 16px;
        color: #888888;
        margin-top: 20px;
    }
</style>